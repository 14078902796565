<script>
import ApiService from "@services/api.service";

export default {
  name: "AdminAccount",
  data() {
    return {
      table: {
        sortBy: "description",
        fields: [
          { key: "username", sortable: true },
          { key: "displayedName", sortable: true, label: "Display Name" },
          { key: "role", sortable: true },
          { key: "status", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        filter: null
      },
      parameter: {
        leadsProgramList: [],
        role: [
          { code: 0, description: "Admin" },
          { code: 1, description: "Product Owner" },
          { code: 2, description: "Head Office" },
          { code: 3, description: "Marketing Communication" }
        ]
      },
      adminAccount: {
        username: null,
        isActive: false,
        displayedName: null,
        password: "bmimdirect",
        role: null,
        adminPrograms: []
      },

      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    },
    editMode: function () {
      return this.adminAccount.code != null;
    }
  },
  created() {
    this.loadTable();
    this.loadLeadsProgram();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("AdminAccount")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    loadLeadsProgram() {
      this.changeLoader(true);
      ApiService.get("LeadsProgram")
        .then(resp => {
          this.parameter.leadsProgramList = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    openDetail(selectedRow) {
      if (selectedRow) {
        this.adminAccount = { ...selectedRow };
        this.adminAccount.adminPrograms = [];
        if (selectedRow.adminPrograms) {
          selectedRow.adminPrograms.map(x => {
            this.adminAccount.adminPrograms.push(x.leadsProgramCode);
          });
        }
      }
      this.showModal = true;
    },
    save() {
      let payload = { ...this.adminAccount };
      payload.adminPrograms = [];
      this.adminAccount.adminPrograms.map(x => {
        let a = { leadsProgramCode: x };
        payload.adminPrograms.push(a);
      });
      if (!this.editMode) {
        this.changeLoader(true);
        ApiService.post("AdminAccount", payload)
          .then(() => {
            this.showToast("success", "Create Successful");
            this.loadTable();
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        ApiService.put("AdminAccount", payload)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.loadTable();
            this.showModal = false;
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    toggle(code) {
      this.changeLoader(true);
      let qs = {
        code: code
      };
      ApiService.patch("AdminAccount", null, qs)
        .then(() => {
          this.showToast("success", "Toggle Successful");
          this.loadTable();
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    clearForm() {
      this.adminAccount = {
        username: null,
        isActive: false,
        displayedName: null,
        password: null,
        confirmPassword: null,
        role: null,
        adminPrograms: []
      };
    },
    roleConvert(value) {
      var x = this.parameter.role.find(x => x.code == value);
      return x ? x.description : "";
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div class="d-flex mb-2">
        <b-btn variant="outline-primary" @click.stop="openDetail(null)">Create</b-btn>
        <div class="ml-auto">
          <b-input v-model="table.filter" placeholder="Filter" debounce="500" class="form-control text-right" />
        </div>
      </div>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        no-provider-filtering
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
        fixed
      >
        <template #cell(role)="data">
          <div>
            {{ roleConvert(data.item.role) }}
          </div>
        </template>
        <template #cell(status)="data">
          <div>
            <b-badge :variant="data.item.isActive ? 'success' : 'warning'" class="text-capitalize">
              {{ data.item.isActive ? "Active" : "Inactive" }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn class="btn btn-sm btn-wide mr-1" :class="data.item.isActive ? 'btn-secondary' : 'btn-info'" @click.stop="toggle(data.item.code)">{{
              data.item.isActive ? "Deactivate" : "Activate"
            }}</b-btn>
            <b-btn class="btn btn-sm btn-wide btn-warning mr-1" @click.stop="openDetail(data.item)">Edit</b-btn>
            <!-- <b-btn class="btn btn-sm btn-wide btn-danger" @click.stop="openDetail(data.item)">Delete</b-btn> -->
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal v-model="showModal" size="lg" hide-footer centered :backdrop="true" @hidden="clearForm">
      <div class="px-3 py-2">
        <b-row>
          <b-col class="text-center">
            <h2>{{ !editMode ? `Create Admin Account` : `Edit Admin Account` }}</h2>
          </b-col>
        </b-row>
        <hr />

        <div v-if="!editMode" class="text-center">
          <b-row>
            <b-col>
              <b>Default Password for newly created user is "bmimdirect"</b>
            </b-col>
          </b-row>
          <hr />
        </div>

        <b-form @submit.stop.prevent="save">
          <b-row>
            <b-col md="4" align-self="center">
              <label>Username</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-input v-model="adminAccount.username" class="form-control" />
              <small>Admin Account Username</small>
            </b-col>
          </b-row>
          <b-row class="mt-2" align-self="center">
            <b-col md="4" align-self="center">
              <label>Display Name</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-input v-model="adminAccount.displayedName" class="form-control" />
              <small>Admin Account Display Name</small>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4" align-self="center">
              <label>Role</label>
            </b-col>
            <b-col md="8" align-self="center">
              <v-select v-model="adminAccount.role" label="description" :options="parameter.role" :reduce="x => x.code" />
              <small>Admin Account Role</small>
            </b-col>
          </b-row>

          <b-row v-if="adminAccount.role === 1" class="mt-1">
            <b-col md="4" align-self="center">
              <label>Program Access</label>
            </b-col>
            <b-col class="row" md="8" align-self="center">
              <b-col v-for="item in parameter.leadsProgramList" :key="item.code" class="mr-4" cols="12" align-self="center">
                <b-form-checkbox v-model="adminAccount.adminPrograms" :value="item.code">
                  {{ item.description }}
                </b-form-checkbox>
              </b-col>
            </b-col>
          </b-row>
          <div class="mt-2">
            <span>* Default Password for newly created user is "bmimdirect"</span>
          </div>
          <b-row class="mt-2">
            <b-col>
              <b-btn block variant="danger" @click.stop="showModal = false">Cancel</b-btn>
            </b-col>
            <b-col>
              <b-btn block type="submit" variant="success">Save</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
